import React, { useEffect, useState } from 'react'
import { createRoot } from 'react-dom/client'
import './css/main.css'
import App from './mod/App'
import Loading from './Loading' // Importa el componente de carga

const container = document.getElementById('root')
const root = createRoot(container)

const Index = () => {
  const [configLoaded, setConfigLoaded] = useState(false)

  useEffect(() => {
    const loadConfig = async () => {
      try {
        const response = await fetch('/config.json')
        if (!response.ok) {
          throw new Error('Network response was not ok')
        }
        const config = await response.json()
        window.infoConfig = config
        setConfigLoaded(true) // Configuración cargada exitosamente
      } catch (error) {
        console.error('Failed to load config:', error)
      }
    }

    loadConfig()
  }, [])

  if (!configLoaded) {
    return <Loading /> // Mostrar el componente de carga
  }

  return (
    <React.StrictMode>
      <App />
    </React.StrictMode>
  )
}
root.render(<Index />)
