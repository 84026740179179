import React from "react";
import "../css/SearchResult.css";
import waitImg from "../img/waiting3.gif";

class SearchResult extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      comercios: undefined,
    };
  }

  // Método para truncar texto
  truncateText(text, maxLength = 200) {
    if (!text) return "";
    if (text.length <= maxLength) return text;

    // Truncar al último espacio antes de maxLength
    const truncated = text.slice(0, maxLength);
    return truncated.slice(0, truncated.lastIndexOf(" ")) + "...";
  }

  // Renderizar lista de comercios
  renderComercios() {
    const { loading, comercios } = this.state;

    if (loading) {
      return (
        <div className="waitingDivCom">
          <img
            className="waitingCom"
            src={waitImg}
            width="10px"
            alt="Cargando..."
          />
        </div>
      );
    }

    if (comercios && comercios.results.length > 0) {
      return (
        <ol>
          {comercios.results.map((item) => (
            <li className="resultList" key={item.id}>
              <div className="resultListBox">
                <div className="empresa">{item.empresa}</div>
                <div>{this.truncateText(item.servicios)}</div>
                <br />
                <div>
                  {item.calle_numero} {item.zona}
                </div>
                <div>
                  {item.telefono1} {item.telefono2} {item.telefono3}
                </div>
              </div>
            </li>
          ))}
        </ol>
      );
    } else {
      return <p>No existen coincidencias para su búsqueda</p>;
    }
  }

  static getDerivedStateFromProps(props, state) {
    if (
      props.comercios !== state.comercios ||
      props.loading !== state.loading
    ) {
      return { comercios: props.comercios, loading: props.loading };
    }
    return null;
  }

  render() {
    return <div>{this.renderComercios()}</div>;
  }
}

SearchResult.displayName = "SearchResult";
export default SearchResult;
