import React from 'react'

class SearchBox extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      ciudad: {},
      txt_busqueda: '',
      zonas_activas: [],
      zna_id: 0,
      msg: 'Busca en...'
    }
    this.handleOnSearchChange = this.handleOnSearchChange.bind(this)
    this.handleOnClearClick = this.handleOnClearClick.bind(this)
  }

  render() {
    return (
      <div className='searchForm'>
        <input
          className='searchInput'
          autoFocus
          value={this.state.txt_busqueda}
          onChange={this.handleOnSearchChange}
          ref={(input) => {
            this.searchInput = input
          }}
          placeholder={this.state.msg}
        />
        <div className='svgClear' onClick={this.handleOnClearClick}>
          <svg width='32px' height='32px' viewBox='0 0 24 24' stroke='none'>
            <path d='M8.3,15.7C8.5,15.9,8.7,16,9,16s0.5-0.1,0.7-0.3l2.3-2.3l2.3,2.3c0.2,0.2,0.5,0.3,0.7,0.3s0.5-0.1,0.7-0.3   c0.4-0.4,0.4-1,0-1.4L13.4,12l2.3-2.3c0.4-0.4,0.4-1,0-1.4s-1-0.4-1.4,0L12,10.6L9.7,8.3c-0.4-0.4-1-0.4-1.4,0s-0.4,1,0,1.4   l2.3,2.3l-2.3,2.3C7.9,14.7,7.9,15.3,8.3,15.7z' />
            <path d='M12,21c5,0,9-4,9-9s-4-9-9-9s-9,4-9,9S7,21,12,21z M12,5c3.9,0,7,3.1,7,7s-3.1,7-7,7s-7-3.1-7-7S8.1,5,12,5z' />
          </svg>
        </div>
      </div>
    )
  }

  static getDerivedStateFromProps(props, state) {
    let msg = ''
    if (
      props.zna_id !== undefined &&
      props.zna_id !== '' &&
      props.zna_id !== 0
    ) {
      const zona = props.zonas_activas.filter((zona) => {
        return zona.id === props.zna_id
      })[0].descripcion
      msg += zona.split('-').join(' ').trim() + ', '
    }
    if (props.ciudad.pais !== undefined) {
      msg += props.ciudad.ciudad + ', ' + props.ciudad.pais
    }

    if (
      props.ciudad !== state.ciudad ||
      props.txt_busqueda !== state.txt_busqueda ||
      props.zonas_activas !== state.zonas_activas ||
      props.zna_id !== state.zna_id
    ) {
      if (msg.length === 0) {
        msg = 'Busca en...'
      }

      return {
        ciudad: props.ciudad,
        txt_busqueda: props.txt_busqueda,
        zonas_activas: props.zonas_activas,
        zna_id: props.zna_id,
        msg: msg
      }
    }
    return null
  }

  componentDidMount() {
    this.searchInput.focus()
  }

  handleOnSearchChange(event) {
    this.props.onSearchTextChanged(event.target.value)
  }

  handleOnClearClick() {
    if (this.state.txt_busqueda.trim() !== '') {
      this.setState({ txt_busqueda: '' })
      this.props.onSearchTextChanged('')
    }
  }
}
SearchBox.displayName = 'SearchBox'
export default SearchBox
