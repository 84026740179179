import React from 'react'
import SidebarMenu from './SidebarMenu'
import SearchBox from './SearchBox'
import Inicio from './Inicio'
import Servicios from './Servicios'
import Error from './Error'
import SearchResult from './SearchResult'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect
} from 'react-router-dom'
import waitImg from '../img/waiting2.gif'

class App extends React.Component {
  counter = 0
  render_offset = 250
  grupo = 0
  gr_aux = 0
  gr_tam = 15
  intervalId = null
  comp_mounting = false

  constructor(props) {
    super(props)

    this.printState = this.printState.bind(this)

    this.renderError = () => {
      if (this.state.redirect) {
        return <Error txt_error={this.state.txt_error}></Error>
      }
    }

    this.renderRoute = () => {
      if (!this.state.redirect) {
        return (
          <Switch>
            <Route exact path='/'>
              <Inicio />
            </Route>
            <Route path='/servicios'>
              <Servicios />
            </Route>
            <Route path='/error'>
              <Error />
            </Route>
            <Route path='/*'>
              <SearchResult
                comercios={this.state.comercios}
                loading={this.state.loading}
              ></SearchResult>
            </Route>
          </Switch>
        )
      }
    }

    this.renderRedirect = () => {
      if (this.state.path !== undefined) {
        return <Redirect to={this.state.path} />
      }
    }

    this.onCiudadChanged = (new_ciudad) => {
      if (!new_ciudad || !new_ciudad.id) {
        console.error('new_ciudad o new_ciudad.id no están definidos')
        return
      }
      this.setState({ ciudad: new_ciudad, lockScreen: 'lockScreen' })
      fetch(`${window.infoConfig.apiUrl}/ciudades/${new_ciudad.id}/zonas`)
        .then((response) => {
          if (!response.ok) {
            throw new Error(
              `Error en la solicitud: ${response.status} ${response.statusText}`
            )
          }
          return response.json() // Parseamos la respuesta JSON
        })
        .then((data) => {
          data.unshift({
            id: 0,
            descripcion: 'Todas las Zonas'
          })
          this.setState({
            zonas_activas: data,
            lockScreen: 'unlockScreen',
            redirect: false,
            txt_error: '',
            zna_id: 0
          })
        })
        .catch((errMsg) => {
          this.setState({
            lockScreen: 'unlockScreen',
            redirect: true,
            txt_error: errMsg
          })
        })
    }

    this.onZonaChanged = (new_zna_id) => {
      this.setState({ zna_id: new_zna_id })
    }

    this.onPanelDismiss = () => {
      let aux = this.linkBuilder(this.state.txt_busqueda)
      this.setState({ path: aux }, () => {
        this.counter = -1 // Reinicia el contador para que se cargue las lista 1
      })
    }

    this.onSearchTextChanged = (search_text) => {
      let aux = this.linkBuilder(search_text)
      this.setState({ path: aux, txt_busqueda: search_text }, () => {
        this.counter = -10 // Reinicia el contador para que se cargue las lista en 10
      })
    }

    this.linkBuilder = (
      txt_busqueda = this.state.txt_busqueda,
      ciudad = this.state.ciudad,
      zna_id = this.state.zna_id,
      zonas_activas = this.state.zonas_activas
    ) => {
      let aux = ''
      if (ciudad.pais !== undefined) {
        aux +=
          '/' +
          ciudad.pais.split(' ').join('-') +
          '/' +
          ciudad.ciudad.split(' ').join('-')
      }
      if (zna_id !== undefined && zna_id !== '' && zna_id !== 0) {
        let zona = zonas_activas.filter((zona) => {
          return zona.id === zna_id
        })
        if (zona.length > 0) {
          aux += '/' + zona[0].descripcion.split(' ').join('-')
        }
      }
      if (txt_busqueda !== '') {
        aux += '/' + txt_busqueda.split(' ').join('-')
      }
      return aux
    }

    this.timer = this.timer.bind(this)
    this.handleScroll = this.handleScroll.bind(this)

    this.state = {
      txt_error: '',
      redirect: false,
      lockScreen: 'lockScreen',
      ciudad: {},
      ciudades: [],
      zonas_activas: [],
      zna_id: 0,
      txt_busqueda: '',
      path: decodeURI(window.location.pathname),
      comercios: undefined,
      last_text: ''
    }
  }

  render() {
    return (
      <Router>
        {this.renderRedirect()}
        <div className='main'>
          <div className={this.state.lockScreen}>
            <img
              className='waitingImg'
              src={waitImg}
              width='100px'
              height='100px'
              alt='Cargando...'
            ></img>
          </div>
          <div className='topBarDiv'>
            <table className='topBar'>
              <tbody>
                <tr>
                  <td className='iconCell'>
                    <SidebarMenu
                      ref={this.sidebarRef}
                      onCiudadChanged={this.onCiudadChanged}
                      onZonaChanged={this.onZonaChanged}
                      onCategoriaChanged={this.onCategoriaChanged}
                      onPanelDismiss={this.onPanelDismiss}
                      ciudad={this.state.ciudad}
                      ciudades={this.state.ciudades}
                      codigos_comercio={this.state.codigos_comercio}
                      zonas_activas={this.state.zonas_activas}
                      zna_id={this.state.zna_id}
                    />
                  </td>
                  <td>
                    <SearchBox
                      onSearchTextChanged={this.onSearchTextChanged}
                      ciudad={this.state.ciudad}
                      txt_busqueda={this.state.txt_busqueda}
                      codigos_comercio={this.state.codigos_comercio}
                      zonas_activas={this.state.zonas_activas}
                      zna_id={this.state.zna_id}
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className='contenido' onScroll={this.handleScroll}>
            {this.renderError()}
            {this.renderRoute()}
          </div>
        </div>
      </Router>
    )
  }

  async componentDidMount() {
    this.counter = 0
    this.intervalId = setInterval(this.timer, 100)

    if (!this.comp_mounting) {
      this.comp_mounting = true

      await this.loadInit()
      this.comp_mounting = false
    }
  }

  componentWillUnmount() {
    clearInterval(this.intervalId)
  }

  timer() {
    this.counter++
    if (this.counter === 0) {
      this.loadSearch(0)
    }
  }

  loadInit = async () => {
    try {
      let paths = this.state.path.split('/').filter((aux_p) => {
        return aux_p !== ''
      })
      let ciudad_ini = null
      let aux_id = 0
      let new_path = []
      let aux_text = ''

      // Actualiza ciudad_ini con la ciudad que viene en la ruta
      if (paths.length >= 2) {
        const response = await fetch(
          `${window.infoConfig.apiUrl}/ciudades?pais=${paths[0].replace(/-/g, ' ')}&ciudad=${paths[1].replace(/-/g, ' ')}`
        )
        if (!response.ok) {
          throw new Error(
            `Error en la solicitud: ${response.status} ${response.statusText}`
          )
        }
        const responseJson = await response.json()
        if (Array.isArray(responseJson) && responseJson.length > 0) {
          ciudad_ini = responseJson
          new_path = paths.slice(2)
        }
      }
      // Si lo que viene en la ruta no es una ciudad o la que viene no se encuentra carga ciudad IP
      if (!ciudad_ini) {
        const response = await fetch(
          `${window.infoConfig.apiUrl}/ciudades/by_client_ip`
        )
        if (!response.ok) {
          throw new Error(
            `Error en la solicitud: ${response.status} ${response.statusText}`
          )
        }
        const responseJson = await response.json()
        if (Array.isArray(responseJson) && responseJson.length > 0) {
          ciudad_ini = responseJson
          new_path = paths
        }
      }

      // Solicitar ciudades
      const responseCiudades = await fetch(
        `${window.infoConfig.apiUrl}/ciudades`
      )
      if (!responseCiudades.ok) {
        throw new Error(
          `Error en la solicitud: ${responseCiudades.status} ${responseCiudades.statusText}`
        )
      }
      const ciudades = await responseCiudades.json()

      // Solicitar zonas de la ciudad inicial
      const responseZonas = await fetch(
        `${window.infoConfig.apiUrl}/ciudades/${ciudad_ini[0].id}/zonas`
      )
      if (!responseZonas.ok) {
        throw new Error(
          `Error en la solicitud: ${responseZonas.status} ${responseZonas.statusText}`
        )
      }
      const zonas = await responseZonas.json()

      // Busca si el tercer parámetro es la zona
      if (new_path.length > 0) {
        const zonaEncontrada = zonas.find(
          (zona) =>
            zona.descripcion.toLowerCase() ===
            new_path[0].toLowerCase().split('-').join(' ')
        )
        if (zonaEncontrada) {
          aux_id = zonaEncontrada.id
          new_path = new_path.slice(1)
        }
      }

      // Agregar "Todas las Zonas" al inicio de las zonas
      zonas.unshift({ id: 0, descripcion: 'Todas las Zonas' })

      aux_text = new_path.join(' ').split('-').join(' ')
      let build_path = this.linkBuilder(aux_text, ciudad_ini[0], aux_id, zonas)

      // Actualizar el estado con las zonas y otros valores
      await this.loadSearch(0, build_path)

      await this.setState({
        ciudad: ciudad_ini[0],
        ciudades: ciudades,
        zonas_activas: zonas,
        zna_id: aux_id,
        txt_busqueda: aux_text,
        lockScreen: 'unlockScreen',
        path: build_path,
        txt_error: ''
      })
    } catch (errMsg) {
      this.setState({
        lockScreen: 'unlockScreen',
        redirect: true,
        txt_error: errMsg.message || 'Error desconocido'
      })
    } finally {
    }
  }

  loadSearch = async (gr = null, text_path = this.state.path) => {
    let still_loading = false

    if (this.state.comercios) {
      still_loading =
        this.state.comercios.count > this.state.comercios.results.length
    }

    let text = text_path.replace(/[/\-]/g, ' ').trim()

    if (text !== '' && (text !== this.state.last_text || still_loading)) {
      try {
        if (gr !== null) this.grupo = gr
        this.gr_aux = this.grupo + 1

        if (this.gr_aux === 1) await this.setState({ loading: true })

        const response = await fetch(
          `${window.infoConfig.apiUrl}/comercios/lista?text=${text}&page=${this.gr_aux}&per_page=${this.gr_tam}`
        )
        if (!response.ok) {
          throw new Error(
            `Error en la solicitud: ${response.status} ${response.statusText}`
          )
        }

        const obj = await response.json()
        await this.setState({ last_text: text })

        if (this.gr_aux === 1) {
          await this.setState({ comercios: obj })
        } else {
          await this.setState((prevState) => ({
            comercios: {
              ...prevState.comercios,
              results: [...prevState.comercios.results, ...obj.results]
            }
          }))
        }

        this.grupo = this.gr_aux
      } catch (errMsg) {
        console.error('Error al recuperar comercios:', errMsg)
      } finally {
        if (this.gr_aux === 1) await this.setState({ loading: false })
      }
    }
  }

  handleScroll = (e) => {
    let element = e.target
    if (
      element.scrollHeight - element.scrollTop - element.clientHeight <=
      this.render_offset
    ) {
      if (this.gr_aux === this.grupo) {
        this.loadSearch()
      }
    }
  }

  printState = () => {
    console.log(`error         = ${this.state.txt_error}`)
    console.log(`redirect      = ${this.state.redirect}`)
    console.log(`lockScreen    = ${this.state.lockScreen}`)
    console.log(`ciudad        = ${this.state.ciudad}`)
    console.log(`ciudades      = ${this.state.ciudades}`)
    console.log(`zonas_activas = ${this.state.zonas_activas}`)
    console.log(`zna_id        = ${this.state.zna_id}`)
    console.log(`txt_busqueda  = ${this.state.txt_busqueda}`)
    console.log(`path          = ${this.state.path}`)
    console.log(`comercios     = ${this.state.comercios}`)
    console.log(`last_text     = ${this.state.last_text}`)
  }
}

App.displayName = 'App'
export default App
